import { mainTableColumnDefaultGroup } from '../../../enums';
import { ColumnTypesEnum, innerColumnName } from '../../ColumnTypesEnum';
import { ColumnComponentModel, ColumnModel } from './column.model';

export class InnerColumn extends ColumnModel {
  actionsAvailableTypes = [];
  alias = innerColumnName;
  group = mainTableColumnDefaultGroup;
  styleClass = '';
  type = ColumnTypesEnum.INNER;

  constructor(config?: { styleClass: string; component?: ColumnComponentModel }) {
    super();

    if (config) {
      config.component && (this.component = config.component);
      config.styleClass && (this.styleClass = config.styleClass);
    }
  }

  returnPossibleValues() {
    return null;
  }

  returnValueForDisplay() {
    return null;
  }

  returnValue() {
    return null;
  }

  isEditable() {
    return null;
  }

  isRequired() {
    return null;
  }

  isAbleToSort() {
    return false;
  }
}
