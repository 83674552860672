import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import * as Sentry from '@sentry/angular';

@Injectable({
  providedIn: 'root',
})
export class SentryService {
  sentryInit(): void {
    if (environment.sentryDsn) {
      Sentry.init({
        release: environment.appVersion,
        dsn: environment.sentryDsn,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
        integrations: [
          Sentry.browserTracingIntegration(),
          Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false,
            networkCaptureBodies: true,
            mutationLimit: 5000,
            maxReplayDuration: 900000,
            networkDetailAllowUrls: [environment.apiUrl],
            networkRequestHeaders: ['account', 'user-agent'],
            networkResponseHeaders: ['X-Custom-Header'],
            networkDetailDenyUrls: [environment.apiUrl + '/login', environment.apiUrl + '/password', environment.apiUrl + '/register'],
          }),
        ],
        tracesSampleRate: 1.0,
      });
    }
  }
}
