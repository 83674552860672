import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AnonymousPaymentPlanComponent } from './anonymous-payment-plan/anonymous-payment-plan.component';
import { CatchTokenComponent } from './catch-token/catch-token.component';
import { FixComponent } from './fix/fix.component';
import { AuthGuard } from './guards/auth.guard.service';
import { GuestGuard } from './guards/quest.guard.service';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { ShopifyAfterPaymentComponent } from './shopify-after-payment/shopify-after-payment.component';
import { ShopifyComponent } from './shopify/shopify.component';

const routes: Routes = [
  {
    path: 'dashboard',
    canActivate: [AuthGuard],
    loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
  },
  {
    path: 'auth',
    canActivate: [GuestGuard],
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'public',
    loadChildren: () => import('./public/public.module').then((m) => m.PublicModule),
  },
  {
    path: 'token/:token',
    component: CatchTokenComponent,
  },
  {
    path: 'plugin',
    component: ShopifyComponent,
  },
  {
    path: 'plugin-payment',
    component: ShopifyAfterPaymentComponent,
  },
  {
    path: 'maintenance',
    title: 'maintenance.title',
    component: MaintenanceComponent,
  },
  {
    path: 'fix',
    component: FixComponent,
  },
  {
    path: 'plan',
    title: 'payments.plan.title',
    component: AnonymousPaymentPlanComponent,
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'auth',
  },
  {
    path: '**',
    redirectTo: '/dashboard/home',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableViewTransitions: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
