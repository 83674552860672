import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import compareVersions from 'compare-versions';
import { Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import GeneralConfig from 'src/app/configs/general.config';
import { ClientVersionInterface, RefreshAppModalComponent } from '../shared/modals/refresh-app-modal/refresh-app-modal.component';
import { AuthService } from '../shared/service/auth.service';
import { PluginService } from '../shared/service/plugin.service';

const HEADER_KEY = {
  version: 'Client-Version',
};

@Injectable()
export class ClientVersionIntercecptorService implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private dialog: MatDialog,
    private pluginService: PluginService,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.pluginService.isPluginMode) {
      return next.handle(req).pipe(
        filter((event): event is HttpResponse<unknown> => event instanceof HttpResponse),
        tap((event) => event && event.headers && this.checkClientVersionFromHeaders(event.headers)),
      );
    }

    return next.handle(req);
  }

  private checkClientVersionFromHeaders(headers: HttpHeaders) {
    if (!headers) {
      return;
    }

    const dialogsNotOpened = !this.dialog.openDialogs.length;

    if (dialogsNotOpened && headers.has(HEADER_KEY.version)) {
      const headerVersion = headers.get(HEADER_KEY.version) || null;
      const appVersion = GeneralConfig.version?.app || null;

      appVersion &&
        compareVersions.validate(headerVersion!) &&
        compareVersions(headerVersion!, appVersion) > 0 &&
        this.showNewVersionDialog(appVersion, headerVersion!);
    }
  }

  private showNewVersionDialog(envVersion: string, headerVersion: string) {
    const versions: ClientVersionInterface = {
      env: envVersion,
      header: headerVersion,
    };

    const dialog = this.dialog.open(RefreshAppModalComponent, {
      panelClass: 'no-dialog-spinner',
      width: 'auto',
      data: versions,
    });

    dialog.beforeClosed().subscribe(() => this.loadNewVersion());
  }

  private loadNewVersion() {
    this.pluginService.isPluginMode ? this.refresh() : this.logout();
  }

  private logout() {
    this.dialog.closeAll();
    this.authService.logout(true, true);
  }

  private refresh() {
    window.location.reload();
  }
}
